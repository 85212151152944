import React from 'react';

import {
  IAccountsPayableServiceDocument,
  TAccountsPayableServiceDocumentsSortColumn,
} from '@src/types/accounts_payable/accounts_payable_service_documents';
import { formatDate } from '@src/utils/date_helpers';

import QBOStatusIcon from '@src/components/common_v2/qbo_status_icon';
import { VendorAvatar } from '@src/components/ui/avatars';
import CollectionTable from '@src/components/ui_v2/collection_table';
import Tooltip from '@src/components/ui_v2/tooltip';

import Action from './actions';
import ChartOfAccountView from '../action_icons/chart_of_account_view/chart_of_account_view';
import InvoiceChat from '../action_icons/invoice_chat';
import InvoiceDueDate from '../action_icons/invoice_due_date';
import InvoiceStarFlag from '../action_icons/invoice_star_flag';
import InvoiceStatus from '../action_icons/invoice_status';
import PossibleMatch from '../action_icons/possible_match';
import InvoiceQueueFilter from '../filter';
import { IInvoiceQueueCollection } from '../hooks';

import styles from '../styles.module.scss';

interface IInvoiceQueueTableProps {
  collection: IInvoiceQueueCollection,
}

const InvoiceQueueTable = ({ collection }: IInvoiceQueueTableProps) => {
  const starFlagIcon = (document: IAccountsPayableServiceDocument) => {
    return <InvoiceStarFlag accountsPayableServiceDocument={ document } />;
  };

  const chatIcon = (accountsPayableServiceDocument: IAccountsPayableServiceDocument) => {
    return <InvoiceChat accountsPayableServiceDocument={ accountsPayableServiceDocument } />;
  };

  const vendorAvatar = (accountsPayableServiceDocument: IAccountsPayableServiceDocument) => {
    return <VendorAvatar size="100%" vendor={ accountsPayableServiceDocument.vendor || null } />;
  };

  const payeeLabel = (accountsPayableServiceDocument: IAccountsPayableServiceDocument) => {
    return (
      <Tooltip.Hover
        className={ styles['inline-grid'] }
        content={ accountsPayableServiceDocument.vendor?.name ?? 'Unknown Vendor' }
      >
        <div className={ styles['table-text-cell-text'] }>
          { accountsPayableServiceDocument.vendor?.name ?? 'Unknown Vendor' }
        </div>
      </Tooltip.Hover>
    );
  };

  const typeLabel = (accountsPayableServiceDocument: IAccountsPayableServiceDocument) => {
    if (accountsPayableServiceDocument.isExpenseReport) {
      return (
        <Tooltip.Hover className={ styles['inline-grid'] } content="Reimbursement">
          <span>Reimbursement</span>
        </Tooltip.Hover>
      );
    }

    return (
      <Tooltip.Hover className={ styles['inline-grid'] } content="Bill">
        <span>Bill</span>
      </Tooltip.Hover>
    );
  };

  const chartOfAccountView = (accountsPayableServiceDocument: IAccountsPayableServiceDocument) => {
    return <ChartOfAccountView accountsPayableServiceDocument={ accountsPayableServiceDocument } />;
  };

  const dueDateFormatted = (accountsPayableServiceDocument: IAccountsPayableServiceDocument) => {
    return <InvoiceDueDate accountsPayableServiceDocument={ accountsPayableServiceDocument } />;
  };

  const invoiceStatusLabel = (accountsPayableServiceDocument: IAccountsPayableServiceDocument) => {
    return <InvoiceStatus accountsPayableServiceDocument={ accountsPayableServiceDocument } />;
  };

  const uploaderName = (accountsPayableServiceDocument: IAccountsPayableServiceDocument) => {
    return (
      <Tooltip.Hover className={ styles['inline-grid'] } content={ accountsPayableServiceDocument.uploaderName }>
        <span className="inline-block">
          { accountsPayableServiceDocument.uploaderName }
        </span>
      </Tooltip.Hover>
    );
  };

  const possibleMatchIcon = (accountsPayableServiceDocument: IAccountsPayableServiceDocument) => {
    return <PossibleMatch accountsPayableServiceDocument={ accountsPayableServiceDocument } />;
  };

  const invoiceQBOStatusIcon = (accountsPayableServiceDocument: IAccountsPayableServiceDocument) => {
    return <QBOStatusIcon fontSize={ 18 } model={ accountsPayableServiceDocument } />;
  };

  const actions = (accountsPayableServiceDocument: IAccountsPayableServiceDocument) => {
    return <Action accountsPayableServiceDocument={ accountsPayableServiceDocument } />;
  };

  return (
    <CollectionTable<IAccountsPayableServiceDocument, TAccountsPayableServiceDocumentsSortColumn>
      isRegionScroll
      showSelect
      stickySelectColumn
      filter={ <InvoiceQueueFilter /> }
      isRowClickable={ () => false }
      query={ collection.query }
      records={ collection.records }
      sorting={ collection.sorting }
    >
      <CollectionTable.IconColumn<IAccountsPayableServiceDocument>
        name="star_flag"
        title="Star Flag"
        value={ starFlagIcon }
      />
      <CollectionTable.IconColumn<IAccountsPayableServiceDocument>
        name="chat"
        title="Chat"
        value={ chatIcon }
      />
      <CollectionTable.TextColumn<IAccountsPayableServiceDocument,
        TAccountsPayableServiceDocumentsSortColumn>
        hideValueTooltip
        icon={ vendorAvatar }
        name="vendor_id"
        sortColumn="merchant"
        title="Payee"
        value={ payeeLabel }
      />
      <CollectionTable.TextColumn<IAccountsPayableServiceDocument,
        TAccountsPayableServiceDocumentsSortColumn>
        hideValueTooltip
        name="invoice_number"
        sortColumn="invoice_number"
        title="Invoice No"
        value={ (document: IAccountsPayableServiceDocument) => document.invoiceNumber }
      />
      <CollectionTable.TextColumn<IAccountsPayableServiceDocument,
        TAccountsPayableServiceDocumentsSortColumn>
        hideValueTooltip
        name="is_expense_report"
        sortColumn="is_expense_report"
        title="Type"
        value={ typeLabel }
      />
      <CollectionTable.TextColumn<IAccountsPayableServiceDocument,
        TAccountsPayableServiceDocumentsSortColumn>
        hideValueTooltip
        name="invoice_date"
        sortColumn="invoice_date"
        title="Bill Date"
        value={ (document: IAccountsPayableServiceDocument) => document.invoiceDate && `${formatDate(document.invoiceDate)}` }
      />
      <CollectionTable.TextColumn<IAccountsPayableServiceDocument,
        TAccountsPayableServiceDocumentsSortColumn>
        hideValueTooltip
        name="category"
        sortColumn="category"
        title="Chart Of Account"
        value={ chartOfAccountView }
      />
      <CollectionTable.TextColumn<IAccountsPayableServiceDocument,
        TAccountsPayableServiceDocumentsSortColumn>
        hideValueTooltip
        name="due_date"
        sortColumn="due_date"
        title="Due In"
        value={ dueDateFormatted }
      />
      <CollectionTable.TextColumn<IAccountsPayableServiceDocument,
        TAccountsPayableServiceDocumentsSortColumn>
        hideValueTooltip
        name="status"
        title="Status"
        value={ invoiceStatusLabel }
      />
      <CollectionTable.TextColumn<IAccountsPayableServiceDocument,
        TAccountsPayableServiceDocumentsSortColumn>
        hideValueTooltip
        name="uploader_name"
        sortColumn="uploader_name"
        title="Uploader"
        value={ uploaderName }
      />
      <CollectionTable.TextColumn<IAccountsPayableServiceDocument,
        TAccountsPayableServiceDocumentsSortColumn>
        hidden
        name="accounting_class"
        title="Department"
        value={ ( document: IAccountsPayableServiceDocument) => {
          if (document.serviceDocumentSplitsCount !== 0) {
            return `${document.serviceDocumentSplitsCount} Splits`;
          }

          return document?.accountingClass?.name || '';
        } }
      />
      <CollectionTable.TextColumn<IAccountsPayableServiceDocument,
        TAccountsPayableServiceDocumentsSortColumn>
        hidden
        name="account_number"
        sortColumn="account_number"
        title="Account No"
        value={ (document: IAccountsPayableServiceDocument) => document.accountNumber || '' }
      />
      <CollectionTable.AmountColumn<IAccountsPayableServiceDocument,
        TAccountsPayableServiceDocumentsSortColumn>
        name="amount"
        sortColumn="invoice_amount"
        title="Amount"
        value={ document => document.invoiceAmount || '0.0' }
      />
      <CollectionTable.IconColumn<IAccountsPayableServiceDocument>
        name="possible_matches"
        title="Possible Matches"
        value={ possibleMatchIcon }
      />
      <CollectionTable.IconColumn<IAccountsPayableServiceDocument>
        name="qbo_sync_status"
        title="QBO Status"
        value={ invoiceQBOStatusIcon }
      />
      <CollectionTable.ActionsColumn<IAccountsPayableServiceDocument>
        name="actions"
        title="Actions"
        value={ actions }
      />
    </CollectionTable>
  );
};

export default React.memo(InvoiceQueueTable);
