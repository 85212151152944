import { createContext, useContext } from 'react';

interface IDetailsRegionContextProps {
  headerRegionHeight: number,
  footerHeight: number,
  secondHeaderHeight: number,
  setHeaderRegionHeight: (height: number) => void,
  setFooterHeight: (height: number) => void,
}

const DetailsRegionContext = createContext<IDetailsRegionContextProps | null>(null);

const useDetailsRegion = (): IDetailsRegionContextProps => {
  const context = useContext(DetailsRegionContext);

  if (!context) {
    throw new Error('useDetailsRegion must be used within a DetailsRegionProvider');
  }

  return context;
};

export { DetailsRegionContext, useDetailsRegion, IDetailsRegionContextProps };
