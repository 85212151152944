/* eslint-disable max-len */
import React, { useState, useEffect, useMemo } from 'react';

import debounce from 'lodash/debounce';

import { useUpdateTransactionServiceDocumentDoNotLearn } from '@src/hooks/queries/transaction_service_documents';
import { useCreateDocumentNote, useDeleteDocumentNote } from '@src/requests/all_transactions';
import { TID } from '@src/types/common';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import VendorInput from '@src/components/reconciliation_center/journal_entries/header/vendor_input';

import styles from '../../styles.module.scss';

const transactionTypes = window.configData.transaction_types;

interface IExpenseProps {
  businessId: TID;
  rowData: ITransactionServiceDocument;
}
interface IVendorOption {
  value: string;
  label?: string;
  helper?: string;
}

const Expense = ({ businessId, rowData }: IExpenseProps) => {
  const [noDocumentNeeded, setNoDocumentNeeded] = useState(rowData.noDocumentRequired || false);
  const [doNotLearn, setDoNotLearn] = useState(rowData.doNotLearn);
  const [noPushQbo, setNoPushQbo] = useState(rowData.noPushQbo);
  const [documentNote, setDocumentNote] = useState<string>(rowData.documentNote?.note || '');
  const [vendor, setVendor] = useState<string>(String(rowData.vendor?.id));
  const isDisabled = rowData.state === 'verified';

  const { mutateAsync: createDocumentNote } = useCreateDocumentNote();
  const { mutateAsync: deleteDocumentNote } = useDeleteDocumentNote();
  const updateTransactionServiceDocumentDoNotLearn = useUpdateTransactionServiceDocumentDoNotLearn();
  const { mutate: updateTransactionServiceDocumentDoNotLearnMutate } = updateTransactionServiceDocumentDoNotLearn;

  const objectToUpdate = {
    id:                 rowData.id,
    doNotLearn,
    noPushQbo,
    user_vendor_id:     vendor,
    vendor_id:          vendor,
    noDocumentRequired: noDocumentNeeded,
  };

  const debouncedCreateNote = useMemo(
    () => debounce((note: string, docId: number) => {
      createDocumentNote({ documentId: docId, note });
    }, 500),
    [createDocumentNote],
  );

  useEffect(() => {
    return () => {
      debouncedCreateNote.cancel();
    };
  }, [debouncedCreateNote]);

  const handleDocumentNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newNote = e.target.value || '';
    setDocumentNote(newNote);
    debouncedCreateNote(newNote, rowData.documentId);
  };

  const handleNoPushQbo = async () => {
    await updateTransactionServiceDocumentDoNotLearnMutate({ ...objectToUpdate, noPushQbo: !noPushQbo });
    setNoPushQbo(!noPushQbo);
  };

  const handleNoDocumentNeeded = async () => {
    if (noDocumentNeeded) {
      setDocumentNote('');
      if (rowData.documentNote && 'id' in rowData.documentNote) {
        deleteDocumentNote({
          documentId: rowData.documentId,
          noteId:     rowData.documentNote.id,
        });
      }
    }
    setNoDocumentNeeded(!noDocumentNeeded);
    await updateTransactionServiceDocumentDoNotLearnMutate({ ...objectToUpdate, noDocumentRequired: !noDocumentNeeded });
  };

  const handleDocytAiLearnChange = async () => {
    await updateTransactionServiceDocumentDoNotLearnMutate({ ...objectToUpdate, doNotLearn: !doNotLearn });
    setDoNotLearn(!doNotLearn);
  };

  const handleVendorChange = async (item: IVendorOption) => {
    await updateTransactionServiceDocumentDoNotLearnMutate({ ...objectToUpdate, user_vendor_id: item?.value, vendor_id: item?.value });
    setVendor(item?.value || '');
  };

  return (
    <div key={ rowData.id }>
      <div className={ styles['sidebar-type-config'] }>
        <div className={ styles['sidebar-data'] }>
          <span>Transaction Type</span>

          {Number(rowData.amount) < 0 ? (
            <select key={ rowData.id } className={ styles['sidebar-data-select'] } defaultValue={ rowData.transactionType }>
              <option value={ transactionTypes.EXPENSE }>Expense</option>
              <option value={ transactionTypes.TRANSFER }>Transfer</option>
              <option value={ transactionTypes.PAYROLL }>Payroll Charges</option>
              <option value={ transactionTypes.REVENUE_REVERSAL }>Revenue Reversal</option>
              <option value={ transactionTypes.EQUITY_WITHDRAWAL }>Equity Withdrawal</option>
              <option value={ transactionTypes.ASSET_PURCHASE }>Asset Withdrawal</option>
              <option value={ transactionTypes.LIABILITY_PAYMENTS }>Liability Payments</option>
              <option value={ transactionTypes.LOAN_PAYMENTS }>Loan Payments</option>
            </select>
          ) : (
            <select key={ rowData.id } className={ styles['sidebar-data-select'] } defaultValue={ rowData.transactionType }>
              <option value={ transactionTypes.Income }>Income</option>
              <option value={ transactionTypes.VendorRefunds }>Vendor Refunds</option>
              <option value={ transactionTypes.Transfer }>Transfer</option>
              <option value={ transactionTypes.PayrollCharges }>Payroll Charges</option>
              <option value={ transactionTypes.OtherIncome }>Other income</option>
              <option value={ transactionTypes.LoanProceeds }>Loan Proceeds</option>
              <option value={ transactionTypes.EquityInvestment }>Equity Investment</option>
              <option value={ transactionTypes.OtherReceivables }>Other Receivables</option>
            </select>
          ) }
        </div>
        <div className={ styles['sidebar-data'] }>
          <span>Vendor*</span>
          <div style={ { width: '280px' } }>
            <VendorInput
              businessId={ businessId }
              disabled={ isDisabled }
              handleSelected={ handleVendorChange }
              value={ vendor }
            />
          </div>
        </div>
        <div className={ styles['sidebar-data'] } style={ { justifyContent: 'start', gap: '63px' } }>
          <span>Category*</span>
          <span style={ { color: '#2196F3', cursor: 'pointer' } }>Select Category</span>
        </div>
        <div className={ styles['sidebar-data'] }>
          <div className={ styles['docyt-ai-learn-container'] }>
            <div className={ styles['switch-wrapper'] }>
              <input
                checked={ !doNotLearn }
                className={ styles['switch-input'] }
                disabled={ isDisabled }
                type="checkbox"
                onChange={ () => handleDocytAiLearnChange() }
              />
              <div className={ styles.switch }>
                <span className={ styles.slider } />
              </div>
            </div>
            <span>Docyt AI will learn the above categorization details</span>
          </div>
        </div>
      </div>
      <div className={ styles['sidebar-type-config'] }>
        <div className={ styles['sidebar-data-checkbox'] }>
          <input
            checked={ noDocumentNeeded }
            className={ isDisabled ? styles['disabled-checkbox'] : '' }
            id="no-document-needed"
            type="checkbox"
            onChange={ isDisabled ? () => {} : handleNoDocumentNeeded }
          />
          <label htmlFor="no-document-needed">No document needed</label>
          <a
            href={ `/businesses/${businessId}/reconciliation_center/settings` }
            style={ { color: '#2196F3', marginLeft: '8px', position: 'absolute', right: '26px' } }
          >
            Docyt AI Settings
          </a>
        </div>
        {noDocumentNeeded && (
          <div>
            <textarea
              key={ rowData.documentId }
              disabled={ isDisabled }
              placeholder="Add note"
              rows={ 2 }
              style={ {
                borderColor: documentNote || isDisabled ? 'initial' : 'red',
                width:       '100%',
              } }
              value={ documentNote }
              onChange={ handleDocumentNoteChange }
            />
            {!documentNote && !isDisabled && (
              <div style={ { color: 'red', marginTop: '4px', display: 'flex', alignItems: 'center', gap: '4px' } }>
                <i className="fa fa-warning" />
                Note is required.
              </div>
            )}
          </div>
        )}
        <div className={ styles['sidebar-data-checkbox'] }>
          <input
            checked={ noPushQbo }
            className={ isDisabled ? styles['disabled-checkbox'] : '' }
            id="no-push-qbo"
            type="checkbox"
            onChange={ isDisabled ? () => {} : handleNoPushQbo }
          />
          <label htmlFor="no-push-qbo">Do not push the transaction to ledger</label>
        </div>
      </div>
    </div>

  );
};

export default Expense;
